import React from "react"
import Navbar from "../components/navbar"
import SEO from "../components/SEO"

export default function Contact() {
    return (
      <main>
        <SEO title="Contact" pathname="/contact" />

        <Navbar></Navbar>

        <div className="p-6 pt-24 mx-auto lg:max-w-screen-md lg:pt-32">
          <h1 className="tracking-tighter lg:text-center">
            <span className="text-pink">/</span>contact
          </h1>
          <p className="text-xl">
            If you have something in mind you would like to discuss with me,
            something you want to collaborate with, any advice you have for or
            want from me, any business lead you would like to refer, please feel
            free to send me a buzz.
          </p>
          <div className="mt-10">
            <p className="text-sm font-semibold uppercase text-pink">
              Personal
            </p>
            <a
              href="mailto:hello@fahimmurshed.com"
              className="tracking-tight no-link-effect"
            >
              <h3>hello@fahimmurshed.com</h3>
            </a>
          </div>
          <div className="my-5">
            <p className="text-sm font-semibold uppercase text-pink">
              Business Inquiry
            </p>
            <a
              href="mailto:fahim@symbl.co"
              className="tracking-tight no-link-effect"
            >
              <h3>fahim@symbl.co</h3>
            </a>
          </div>
          <div className="mt-5 mb-10">
            <p className="text-sm font-semibold uppercase text-pink">
              Social Profiles
            </p>
            <a
              href="https://www.linkedin.com/in/fahimmurshed"
              className="block text-xl font-semibold tracking-tight no-link-effect"
              target="_blank"
              rel="noreferrer"
            >
              LinkedIn
            </a>
            <a
              href="https://github.com/fahimmurshed14"
              className="block text-xl font-semibold tracking-tight no-link-effect"
              target="_blank"
              rel="noreferrer"
            >
              GitHub
            </a>
            <a
              href="https://twitter.com/fahimmurshedo_o"
              className="block text-xl font-semibold tracking-tight no-link-effect"
              target="_blank"
              rel="noreferrer"
            >
              Twitter
            </a>
            <a
              href="https://www.facebook.com/fahim.murshed1/"
              className="block text-xl font-semibold tracking-tight no-link-effect"
              target="_blank"
              rel="noreferrer"
            >
              Facebook
            </a>
            <a
              href="https://instagram.com/fahimmurshedo_o"
              className="block text-xl font-semibold tracking-tight no-link-effect"
              target="_blank"
              rel="noreferrer"
            >
              Instagram
            </a>
            <a
              href="https://www.behance.net/fahimmurshedo_o"
              className="block text-xl font-semibold tracking-tight no-link-effect"
              target="_blank"
              rel="noreferrer"
            >
              Behance
            </a>
            <a
              href="http://goodreads.com/fahimmurshedo_o"
              className="block text-xl font-semibold tracking-tight no-link-effect"
              target="_blank"
              rel="noreferrer"
            >
              Goodreads
            </a>
          </div>
        </div>
      </main>
    )
}